import React, { useState } from "react"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import Feedback from "../components/app/profile/feedback"

const App = () => {
  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  return (
    <div>
      <button className="btn-light" onClick={onOpenModal}>
        Open modal
      </button>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "customModal",
        }}
      >
        <Feedback onCloseModal={onCloseModal} />
      </Modal>
    </div>
  )
}

export default App

function ModalContent({ onCloseModal }) {
  return (
    <div className="flex flex-col space-y-4 mt-4">
      <h2 className="font-base text-xl">Simple centered modal</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pulvinar
        risus non risus hendrerit venenatis. Pellentesque sit amet hendrerit
        risus, sed porttitor quam.
      </p>
      <button onClick={onCloseModal} className="btn w-full">
        Show
      </button>
    </div>
  )
}
